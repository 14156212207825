import React, { ReactNode } from 'react'
import styles from './CommonHeader.module.scss';

type propTypes = {
    title: string | ReactNode,
    className?: string,
    info?: string | ReactNode
}

const CommonHeader = ({ title, className, info }: propTypes) => {
    return (
        <div className={`${styles.common_header} ${className || ""}`}>
            <h2>{title}</h2>
            {
                info &&
                <p>{info}</p>
            }
        </div>
    )
}

export default CommonHeader
