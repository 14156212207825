import React, { useEffect, useMemo, useState } from 'react'
import styles from "./AuthLayout.module.scss";
import Header from '../../Common/Header/Header';
import { Outlet, useLocation } from 'react-router-dom';
import Footer from '../../Common/Footer/Footer';

const AuthLayout = () => {

    const location = useLocation();
    const [showHeader, setShowHeader] = useState(true);


    let noHeaderRoutes = useMemo(() => [
        {
            id: 1,
            path: "/auth/cookies",
        }
    ], []);

    useEffect(() => {
        setShowHeader(!(noHeaderRoutes.filter(
            item => location.pathname === item.path).length > 0
        ));
    }, [location, noHeaderRoutes])
    return (
        <main className={`${styles.auth_layout} ${!showHeader ? styles.no_header : ""}`}>
            {showHeader && <Header />}
            <Outlet />
            {showHeader && <Footer />}
        </main>
    )
}

export default AuthLayout
