import React from 'react'
import styles from './Toggler.module.scss'

type propTypes = {
    active?: boolean,
    handleActive?: () => void,
}
const Toggler = ({ active, handleActive }: propTypes) => {
    return (
        <button onClick={handleActive} className={`d-lg-none ${styles.toggler} ${active ? styles.active : ''}`}>
            <span></span>
            <span></span>
            <span></span>
        </button>
    )
}

export default Toggler
