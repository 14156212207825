import React, { Suspense, lazy, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Dispatch } from "redux";
import { bnbDecimals } from "./Redux/Slices/ico.slice";
// import { ErrorBoundary } from "./Components/Common/ErrorBoundary/Errorboundary";
// import Loader from "./Components/Common/Loaders/Loader/Loader";
import ErrorPage from "./Components/Pages/Errors/PageNotFound/PageNotFound";
import ErrorFallback from "./Components/Pages/Errors/ErrorFallback/ErrorFallback";
import Spinner from "./Components/Common/Loaders/Spinner/Spinner";
import Homelayout from "./Components/Layouts/HomeLayout/Homelayout";
import Home from "./Components/Pages/Landingpage/Home/Home";
import CookiesPolicy from "./Components/Pages/CookiesPolicy/CookiesPolicy";
import AuthLayout from "./Components/Layouts/AuthLayout/AuthLayout";
import Game from "./Components/Pages/AuthPages/Game/Game";
// const Homelayout = lazy(
//   () => import("./Components/Layouts/HomeLayout/Homelayout")
// );
// const Home = lazy(() => import("./Components/Pages/Landingpage/Home/Home"));
// const CookiesPolicy = lazy(
//   () => import("./Components/Pages/CookiesPolicy/CookiesPolicy")
// );
// const AuthLayout = lazy(
//   () => import("./Components/Layouts/AuthLayout/AuthLayout")
// );

const Application: React.FC = () => {
  /**CREATE DISPATCH OBJECT */
  const dispatch: Dispatch<any> = useDispatch();

  /**GET STATES FROM STORE */
  useEffect(() => {
    /**FUNCTION FOR SET DECIMALS ON PAGE LOAD */
    const getDecimals = async () => {
      // let getUsdtDecimals: any = await dispatch();
      // callContractGetMethod("decimals", [], "dynamic", false, USDT_ADDRESS)
      // dispatch(usdtDecimals(getUsdtDecimals));
      dispatch(bnbDecimals(18));
    };

    getDecimals();
  }, [dispatch]);

  const theme = useSelector((state: any) => state?.theme?.theme);

  useEffect(() => {
    document.body.className = `theme-${theme}`;
  }, [theme]);
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Homelayout />,
      errorElement: <ErrorFallback />,
      children: [
        {
          index: true,
          element: <Home />,
          // element: <CookiesPolicy />,
        },
        // {
        //   path: "home",
        //   element: <Home />,
        // },
        {
          path: "*",
          element: <ErrorPage />,
        },
      ],

      // children: [
      //   {
      //     path: "/",
      //     element: <ErrorPage />,
      //   },
      // ],
    },
    {
      path: "auth",
      element: <AuthLayout />,
      errorElement: <ErrorFallback />,
      children: [
        {
          path: "game",
          element: <Game />,
        },
      ]
    }
  ]);

  return (
    <>
      {/* <Suspense fallback={<Spinner />}> */}
      <RouterProvider router={router} fallbackElement={<Spinner />} />
      {/* </Suspense> */}
    </>
  );
};

export default Application;
