import React from 'react'
import { Spinner as BootstrapSpinner } from 'react-bootstrap'
import styles from "./Spinner.module.scss";

const Spinner = () => {
    return (
        <div className={styles.spinner}>
            <BootstrapSpinner />
        </div>
    )
}

export default Spinner
