import React from 'react';
import styles from "./HeadingText.module.scss";

type propTypes = {
    heading?: string,
    className?: string,
    info?: string,
}

const HeadingText = ({ heading, className, info }: propTypes) => {
    return (
        <div className={`${styles.heading_text} ${className || ""}`}>
            <h2>{heading}</h2>
            {
                info &&
                <p>{info}</p>
            }
        </div>
    )
}

export default HeadingText
