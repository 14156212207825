import React from "react";
import videoUrl from "../../../../Assets/videos/loading-animation.mp4";
import styles from "./Loader.module.scss";

const Loader = () => {
  return (
    <>
      <video
        autoPlay
        // controls
        muted
        className={styles.video}
      >
        <source
          src={"https://d3huscqv5p0xk7.cloudfront.net/loading-animation.mp4"}
          type="video/mp4"
        />
      </video>
    </>
  );
};

export default Loader;
