import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import styles from './Footer.module.scss'
import img1 from '../../../Assets/Images/footer-m.png'
import coin_gecko from '../../../Assets/Images/coingecko.svg'
import { Link } from 'react-router-dom'
import { Coard, Coards, Facebook, Instagram, Load, Telegram, TelegramIcon, Twitter, Youtube } from '../../../Assets/SVG/svgImages'
import binanceIcon from "../../../Assets/Images/bsc-logo.png";
import { handleCursorClass } from '../Cursor/CursorFunctions'

const Footer = () => {
    const handleEnter = e => {
        handleCursorClass("active");
    }
    const handleLeave = e => {
        handleCursorClass("active", "remove");
    }
    return (
        <footer className={styles.FooterSec}>
            <Container>
                <div className={styles.FooterSec_box}>
                    <Row>
                        <Col xl={9}>
                            <div className={styles.FooterSec_box_links}>
                                <div className={styles.footer_img}>
                                    <span className={styles.footer_logo}>
                                        <img src={img1} alt="" />
                                    </span>
                                    <span className={styles.footer_logo}>
                                        <img src={coin_gecko} alt="" />
                                    </span>
                                </div>
                                <div className={styles.footer_links}>
                                    <ul className={styles.unstyle_list}>
                                        <li>
                                            <Link to="/">Terms and conditions</Link>
                                        </li>
                                        <li>
                                            <Link to="/">Privacy Policy</Link>
                                        </li>
                                        <li>
                                            <Link to="/">Whitepaper</Link>
                                        </li>
                                        <li>
                                            <Link to="mailto:chris@spookyshiba.com">Contact Us</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                        <Col xl={3}>
                            <div className={styles.FooterSec_box_socialLinks}>
                                <ul>
                                    <li
                                        onMouseEnter={handleEnter}
                                        onMouseLeave={handleLeave}
                                    >
                                        <Link to="https://twitter.com/SPKYSHIB" target='_blank'>
                                            <Twitter />
                                        </Link>
                                    </li>
                                    <li
                                        onMouseEnter={handleEnter}
                                        onMouseLeave={handleLeave}
                                    >
                                        <Link to="https://t.me/SpookyShibaOfficial" target='_blank'>
                                            <Telegram />
                                        </Link>
                                    </li>
                                    {/* <li
                                        onMouseEnter={handleEnter}
                                        onMouseLeave={handleLeave}
                                    >
                                        <Link to="/">
                                            <TelegramIcon />
                                        </Link>
                                    </li> */}
                                    {/* <li
                                        onMouseEnter={handleEnter}
                                        onMouseLeave={handleLeave}
                                    >
                                        <Link to="/">
                                            <Load />
                                        </Link>
                                    </li>
                                    <li
                                        onMouseEnter={handleEnter}
                                        onMouseLeave={handleLeave}
                                    >
                                        <Link to="/">
                                            <Coard />
                                        </Link>
                                    </li>
                                    <li
                                        onMouseEnter={handleEnter}
                                        onMouseLeave={handleLeave}
                                    >
                                        <Link to="/">
                                            <Facebook />
                                        </Link>
                                    </li>
                                    <li
                                        onMouseEnter={handleEnter}
                                        onMouseLeave={handleLeave}
                                    >
                                        <Link to="/">
                                            <Coards />
                                        </Link>
                                    </li>
                                    <li
                                        onMouseEnter={handleEnter}
                                        onMouseLeave={handleLeave}
                                    >
                                        <Link to="/">
                                            <Youtube />
                                        </Link>
                                    </li>
                                    <li
                                        onMouseEnter={handleEnter}
                                        onMouseLeave={handleLeave}
                                    >
                                        <Link to="/">
                                            <Instagram />
                                        </Link>
                                    </li> */}
                                </ul>
                            </div>
                            {/* <div className={`${styles.list_links} ${styles.FooterSec_box_socialLinks}`}>
                                <ul>
                                    <li
                                        onMouseEnter={handleEnter}
                                        onMouseLeave={handleLeave}
                                    >
                                        <Link to="/">
                                            <Facebook />
                                        </Link>
                                    </li>
                                    <li
                                        onMouseEnter={handleEnter}
                                        onMouseLeave={handleLeave}
                                    >
                                        <Link to="/">
                                            <Coards />
                                        </Link>
                                    </li>
                                    <li
                                        onMouseEnter={handleEnter}
                                        onMouseLeave={handleLeave}
                                    >
                                        <Link to="/">
                                            <Youtube />
                                        </Link>
                                    </li>
                                    <li
                                        onMouseEnter={handleEnter}
                                        onMouseLeave={handleLeave}
                                    >
                                        <Link to="/">
                                            <Instagram />
                                        </Link>
                                    </li>
                                </ul>
                            </div> */}
                        </Col>
                        <Col md={12}>
                            <div className={styles.FooterSec_box_copyright}>
                                <span>
                                    © {new Date().getFullYear()} Spooky Shiba   - All rights reserved.
                                </span>
                                <div className=" ms-md-auto justify-content-md-start justify-content-center mt-md-0 mt-4  d-flex align-items-center">
                                    <h5 className='d-block'>Powered By</h5>
                                    <img src={binanceIcon} alt="" />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </footer>
    )
}

export default Footer
