import React from 'react'
import styles from './Backers.module.scss'
import { Container } from 'react-bootstrap'
import backers_img from '../../../../Assets/Images/backers/angel-investors.png';
import pumpkin from '../../../../Assets/Images/pumpkin.png'
import CommonHeader from "../../../Common/CommonHeader/CommonHeader"
import Slider from "react-slick";


const Backers = () => {
    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        // responsive: [
        //     {
        //         breakpoint: 1366,
        //         settings: {
        //             slidesToShow: 4,
        //             slidesToScroll: 1,
        //             infinite: true,
        //             dots: true
        //         }
        //     },
        //     {
        //         breakpoint: 1024,
        //         settings: {
        //             slidesToShow: 3,
        //             slidesToScroll: 1,
        //             infinite: true,
        //             dots: true
        //         }
        //     },
        //     {
        //         breakpoint: 767,
        //         settings: {
        //             slidesToShow: 2,
        //             slidesToScroll: 1,
        //             initialSlide: 2
        //         }
        //     },
        //     {
        //         breakpoint: 575,
        //         settings: {
        //             slidesToShow: 1,
        //             slidesToScroll: 1
        //         }
        //     }
        // ]
    };
    // const backers = [
    //     {
    //         backers_img: backers_img,
    //     },
    //     {
    //         backers_img: backers_img2,
    //     },
    //     {
    //         backers_img: backers_img3,
    //     },
    //     {
    //         backers_img: backers_img4,
    //     },
    //     {
    //         backers_img: backers_img5,
    //     },
    // ];
    return (
        <section className={styles.Backers_sec}>
            <Container>
                <div className={styles.Backers_sec_box}>
                    <CommonHeader
                        title="Backers"
                    />
                    <div className={styles.Backers_sec_box_row}>
                        {/* <ul> */}
                        <Slider {...settings}>
                            <div className={styles.buy_col}>
                                <div className={styles.backers_img}>
                                    <img src={backers_img} className={styles.back_img} alt="backer-img" />
                                </div>
                            </div>
                        </Slider>

                        {/* <Row>
                            {backers.map((item) => {
                                return (
                                    <Col lg={2} md={4} sm={6} className={styles.buy_col}>
                                        <div className={styles.backers_img}>
                                            <img src={item.backers_img} alt="backer-img" />
                                        </div>
                                    </Col>
                                    // <li>
                                    //     <div className="backers_img">
                                    //         <img src={item.backers_img} alt="backer-img" />
                                    //     </div>
                                    // </li>
                                )
                            })}
                        </Row> */}
                        {/* </ul> */}
                    </div>
                </div>
                <div className={styles.Backers_sec_pumpkinImg}>
                    <img src={pumpkin} alt="pumpkin" />
                </div>
            </Container>
        </section>
    )
}

export default Backers;