import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import bigLogo from '../../../Assets/Images/logo.png';
import styles from './Header.module.scss';
import { Link } from 'react-router-dom';
import Toggler from './Toggler/Toggler';
import HeaderNavigation from './HeaderNavigation/HeaderNavigation';
import smallLogo from '../../../Assets/Images/small-logo.png';
import ConnectWallet from '../Modals/ConnectWallet/ConnectWallet';

const Header = () => {
    const [active, setActive] = useState(false);
    const [logo, setLogo] = useState(bigLogo);
    const [scrolled, setScrolled] = useState(false);
    window.onscroll = () => {
        setScrolled(window.scrollY > 10)
    }

    window.onresize = () => {
        setLogo(document.body.clientWidth < 991 ? smallLogo : bigLogo);
    }

    useEffect(() => {
        document.body.clientWidth < 991 && setLogo(smallLogo);
    }, [])

    return (
        <header className={`${styles.header} ${scrolled ? styles.active : ''}`}>
            <Container>
                <div className={styles.header_inner}>
                    <Link to="/" className={styles.header_logo}>
                        <img src={logo} alt="logo" />
                    </Link>
                    <div className={styles.header_navigation}>
                        <HeaderNavigation
                            // data={navigationLinks}
                            handleActive={() => setActive(false)}
                            active={active}
                        />
                        {/* <ConnectWallet /> */}
                        <Toggler
                            active={active}
                            handleActive={() => setActive(!active)}
                        />
                    </div>
                </div>
            </Container>
        </header>
    )
}

export default Header
