import React, { Fragment } from 'react';
import styles from './Roadmap.module.scss';
import { Container } from 'react-bootstrap';
import RoadmapBox from './RoadmapBox/RoadmapBox';
// import roadmapLine from '../../../Assets/Images/roadmap-line.svg';
import Slider from "react-slick";
import CommonHeader from '../../../Common/CommonHeader/CommonHeader';

const Roadmap = () => {
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        arrows: false,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1365,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 475,
                settings: {
                    slidesToShow: 1,
                }
            },
        ]
    };

    const roadmapData = [
        {
            title: "Q2",
            year: "2023",
            text: [
                { text: "Legal Setup" },
                { text: "Token" },
                { text: "Vesting" },
            ]
        },
        {
            title: "Q3",
            year: "2023",
            text: [
                { text: "Staking" },
                { text: "Gaming Studio Onboarding" },
                { text: "Marketing Strategy" },
            ]
        },
        {
            title: "Q4",
            year: "2023",
            text: [
                { text: "NFTs Suite" },
                { text: "GDD" },
                { text: "1st NFT Drop" },
            ]
        },
        {
            title: "Q1",
            year: "2024",
            text: [
                { text: "In-Game Objects" },
                { text: "2nd NFT Drop" },
            ]
        },
        {
            title: "Q2",
            year: "2024",
            text: [
                { text: "Game Play" },
                { text: "3-4th NFT Drop" },
                { text: "Pre-Swap" },
            ]
        },
        {
            title: "Q3",
            year: "2024",
            text: [
                { text: "Marketplace" },
                { text: "Alpha Testing" },
                { text: "Private Beta" },
                { text: "TGE/ICO" }
            ]
        },
        {
            title: "Q4",
            year: "2024",
            text: [
                { text: "Public Beta" },
                { text: "NFT Land Sale" },
                { text: "Farming" },
                { text: "DeFi Loans" }
            ]
        },
        {
            title: "Q1",
            year: "2025",
            text: [
                { text: "In-Person Gamer Events" },
                { text: "Monitoring of Storyline Evolvement" },
            ]
        },
    ]
    return (
        <section className={styles.roadmap}>
            <Container>
                <CommonHeader
                    title="ROADMAP"
                    // info="Lorem Ipsum is simply dummy text simply dummy text of the printing and typesetting industry. "
                    className={styles.roadmap_heading}
                />
                <div className={styles.roadmap_inner}>
                    {/* <span className={styles.roadmap_line}>
                        <img src={roadmapLine} alt="dashed-line" />
                    </span> */}
                    <div className={styles.roadmap_container}>
                        <Slider {...settings}>
                            {
                                roadmapData.map((item, index) => {
                                    return (
                                        <Fragment key={index}>
                                            <RoadmapBox
                                                heading={item.title}
                                                year={item.year}
                                                textList={item.text}
                                                className={styles[item.title + item.year]}
                                            />
                                        </Fragment>
                                    )
                                })
                            }
                        </Slider>
                    </div>
                </div>
            </Container>
        </section>
    )
}

export default Roadmap
