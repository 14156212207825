import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import styles from './Team.module.scss'
import CommonHeader from '../../../Common/CommonHeader/CommonHeader'
import team_img1 from '../../../../Assets/Images/team/ceo.png'
import team_img2 from '../../../../Assets/Images/team/cfo.png'
import team_img3 from '../../../../Assets/Images/team/coo.png'
import team_img4 from '../../../../Assets/Images/team/cto.png'
import team_img5 from '../../../../Assets/Images/team/cso.png'
import team_img6 from '../../../../Assets/Images/team/cmo.png'
import team_img7 from '../../../../Assets/Images/team/merch-lead.png'
import team_img8 from '../../../../Assets/Images/team/kol.png'
import ProfileCard from '../../../Common/ProfileCard/ProfileCard'

const Team = () => {
    const profile = [
        {
            img: team_img1,
            linked_in: "https://twitter.com/GerhardHacher",
            heading: "Gerhard Hacher",
            heading_info: "CEO",
            inner_data: "Top Real Estate Broker and marketing Leader, turned crypto Investor. In 2017, invested in BNB and fell in love with Binance Smart Chain.Craving to build his own Project, joined the strong community of Spooky Shiba. Gathered skilled individuals to build the web3 game",
        },
        {
            img: team_img2,
            linked_in: "https://twitter.com/BillanorieShort",
            heading: "Anil",
            heading_info: "CFO",
            inner_data: "Banking software Specialist. Financial application Developer. 3 years in web3 and crypto.Creator of the Spooky gaming hub referral system.Chief Financial Officer of Spooky Shiba",
        },
        {
            img: team_img3,
            linked_in: "https://twitter.com/Christo51822787",
            heading: "Chris Albrecht",
            heading_info: "COO",
            inner_data: "In crypto for the last 8 years. Family Office Manager, web3 Investor and e-gaming enthusiast. Persistently focusing on Spooky Shiba's long-term ecosystem growth. Overseeing operations.",
        },
        {
            img: team_img4,
            linked_in: "https://twitter.com/spkymafia",
            heading: "Kirils Kackans",
            heading_info: "CTO",
            inner_data: `Author of "Bitcoin as a currency and billing unit", awarded in 2018. 7 years in crypto and counting. On IDO of SPKY, couldn't resist and invested. As gem performed well and gained a massive community backing, Kirils has become a Whale. Engaged in building the ecosystem has organically become a core Team Member.`,
        },
        {
            img: team_img5,
            linked_in: "https://twitter.com/MalikMoiz191393",
            heading: "Mo",
            heading_info: "CSO",
            inner_data: "4 years in crypto and web3 development. Mobile and web app Specialist.Successfully delivered multiple gigs. Experienced Project Manager.Currently Spooky Shiba's Chief Security Officer.",
        },
        {
            img: team_img6,
            linked_in: "https://twitter.com/risespookyshiba",
            heading: "Jamie Coleman",
            heading_info: "CMO",
            inner_data: "With long background in competitive gaming, he is the perfect match for growth and spooky adoption. In crypto since the epidemic-driven bull run, worked with plenty of projects at the time, to finally shift his focus on building the brand of the most promising one - Spooky Shiba. ",
        },
        {
            img: team_img7,
            linked_in: "https://twitter.com/kath_217",
            heading: "Kathryn",
            heading_info: "Merch Lead",
            inner_data: "Award-winning fashion and graphic Designer. Ex-fashion brand owner, exited her business to travel the World. Currently designing Spooky Shiba'smerchandise.",
        },
        {
            img: team_img8,
            linked_in: "https://twitter.com/Wrennounced",
            heading: "Lowgun",
            heading_info: "KOL",
            inner_data: "Web3 Developer and Key Opinion Leader in BSC space. Original deployer of Spooky Shiba V1 and forever Ambassador. ",
        },
    ];
    return (
        <section className={styles.TeamSec} id="team">
            <Container>
                <CommonHeader
                    title="Team"
                // info="Lorem Ipsum is simply dummy text simply dummy text of the printing and typesetting industry. "
                />
                <div className={styles.TeamSec_box}>
                    <Row>
                        {profile.map((data, index) => {
                            return (
                                <Col key={index} sm={6} lg={4} xl={3}>
                                    <ProfileCard
                                        image={data.img}
                                        name={data.heading}
                                        designation={data.heading_info}
                                        aboutText={data.inner_data}
                                        linkedInLink={data.linked_in}
                                    />
                                </Col>
                            )
                        })}
                    </Row>
                </div>
            </Container>
            {/* <div className={styles.team_position}>
                <img src={team_bg} alt="team-bg"/>
            </div> */}
        </section>
    )
}

export default Team
